<template>
  <!-- :headers="headers" -->
  <el-upload
    class="upload-demo"
    :action="action"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :multiple="false"
    :accept="accept"
    :limit="10"
    :on-exceed="handleExceed"
    :file-list="fileList"
    :on-success="handleSuccess"
    :on-error="handleError"
    :on-progress="handleProgress"
  >
    <el-button
      size="mini"
      type="primary"
      style="font-size: 10px; font-family: ali; letter-spacing: 3px"
    >
      点击上传
    </el-button>
    <div slot="tip" class="el-upload__tip">
      {{ tip }}
    </div>
  </el-upload>
</template>
<script>
  import config from "../config/config.js";
  export default {
    name: "UploadFile",
    props: {
      tip: {
        type: String,
        default: () => "",
      },
      accept: {
        type: String,
        default: () => "",
      },
      fileListName: {
        type: String,
        default: () => "",
      },
    },
    data() {
      return {
        action: config.uploadApi,
        // headers: {
        //   // 'Content-Type': contentType,
        //   token: this.$store.getters["user/token"]
        // },
        ifuploading: false,
        fileList: [],
      };
    },
    created() {},
    methods: {
      getList() {
        console.log("getList", this.fileList);
      },
      handleRemove(file, fileList) {
        this.ifuploading = false;
        console.log(file, fileList, this.fileList);
        this.fileList = fileList;
        this.$emit("upsuccess", this.fileListName, fileList);
      },

      handlePreview(file) {
        console.log(file);
      },
      handleSuccess(response, file, fileList) {
        this.ifuploading = false;
        const size = file.size / 1024 / 1024;
        this.fileList = fileList;
        console.log("文件大小", size);
        this.$emit("upsuccess", this.fileListName, fileList);
      },
      handleError(err, file, fileList) {
        const size = file.size / 1024 / 1024;
        console.log("文件大小", size);
        this.ifuploading = false;
        console.log(err, file, fileList);
      },
      handleProgress(event, file, fileList) {
        this.ifuploading = true;
        console.log(event, file, fileList);
      },
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 10 个文件，本次选择了 ${
            files.length
          } 个文件，共选择了 ${files.length + fileList.length} 个文件`
        );
      },
      beforeRemove(file, fileList) {
        // return this.$confirm(`确定移除 ${file.name}？`)
      },
    },
  };
</script>
