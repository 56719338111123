<template>
  <div class="zdialog">
    <el-dialog
      class="zdialog"
      :title="title"
      :visible.sync="dialogVisible"
      :width="width"
      :center="center"
      @close="handleClose"
      :close-on-click-modal="modalclose"
      :close-on-press-escape="modalclose"
    >
      <slot></slot>

      <template #footer v-if="ifFoot">
        <slot name="footer">
          <el-button type="primary" :loading="btnloading" @click="onSubmit">
            提交
          </el-button>
        </slot>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "zDialog",
    components: {},
    props: {
      ifFoot: {
        type: Boolean,
        default: () => false,
      },
      center: {
        type: Boolean,
        default: () => false,
      },
      modalclose: {
        type: Boolean,
        default: () => true,
      },
      width: {
        type: String,
        default: () => "",
      },
    },
    data() {
      return {
        btnloading: false,
        title: "产业嘉宾须知",
        dialogVisible: false,
      };
    },
    beforeDestroy() {},
    methods: {
      show({ title = "", page = 1, row = {} } = {}) {
        this.page = page;
        this.title = title || "提示";
        this.dialogVisible = true;
      },
      setTitle(title) {
        this.title = title;
      },
      onSubmit() {
        this.btnloading = true;
        console.log("onSubmit");
        this.$emit("onSubmit");
      },
      handleClose() {
        this.$emit("close");
      },
    },
    watch: {},
    created() {},
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    destroyed() {},
    activated() {},
  };
</script>
<style lang="postcss">
  .zdialog {
    /* width: 1200px; */
  }
  .el-dialog__headerbtn {
    /* top: 12px !important; */
  }
  .el-dialog__header {
    /* height: 0px; */
    padding: 20px 20px 0 20px;
    font-family: ali;
    font-weight: 500;
  }
  .el-dialog__body {
    padding: 10px 30px 30px 30px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    font-family: ali;
  }
</style>
