<template>
  <div id="project-submission">
    <div class="bmtd-container">
      <div id="con" class="con"></div>
    </div>
  </div>
</template>
<script>
  import $ from "jquery";
  export default {
    name: "VolunteerInfo",
    components: {},
    computed: {},
    data() {
      return {};
    },
    methods: {
      initData() {
        let url = "https://www.qndyz.zjfilm.cn/Open/VolunteerInfoH5";
        $(document).ready(function () {
          $("#con").load(url);
        });
      },
    },
    created() {},
    mounted() {
      this.initData();
    },
  };
</script>
<style lang="postcss">
  .bmtd-container {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url("../../../assets/images/bg.png") no-repeat;
  }
  .bmtd-item {
    font-family: ali;
    font-size: 10px;
  }
  .small-button {
    font-size: 12px;
    font-family: ali;
    letter-spacing: 3px;
  }
  .small-title {
    font-size: 12px;
    font-family: ali;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .el-picker-panel {
    top: 15% !important;
  }

  .el-message {
    top: 60px !important;
  }
  .el-input {
    font-size: 10px;
  }
  .el-checkbox__label {
    font-size: 10px;
    font-family: ali;
  }
  .el-dialog__title {
    font-size: 14px;
  }
  .el-form-item__label {
    font-size: 10px;
    font-family: ali;
    line-height: 0;
  }
  .el-upload-list__item {
    font-size: 12px;
    font-family: ali;
  }
</style>
