<template>
  <div id="film">
    <div class="film-container">
      <div
        class="image-cover"
        :style="'background-image:url(' + film.ImgUrl + ')'"
      >
        <div class="film-image" v-cloak>
          <img :src="film.ImgUrl" :alt="film.Name" />
        </div>
      </div>
      <div class="film-details">
        <div class="film-title">
          {{ film.Name }}
        </div>
        <div class="film-content" v-html="film.Content"></div>
      </div>
    </div>
    <div class="turn-page">
      <div class="page-item">
        <img
          :src="prevId == -1 ? prevArrow.imgGray : prevArrow.img"
          style="float: left"
          @click="gotoFilm(prevId)"
        />
        <img
          :src="nextId == -1 ? nextArrow.imgGray : nextArrow.img"
          style="float: right"
          @click="gotoFilm(nextId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import wx from "weixin-js-sdk";
  import { getFilm, getFilmList, getSignature } from "@/network/index";
  export default {
    name: "Film",
    data() {
      return {
        film: {},
        nextId: -99,
        nextArrow: {
          img: require("@/assets/images/arrow_right.png"),
          imgGray: require("@/assets/images/arrow_right_gray.png"),
        },
        prevId: -99,
        prevArrow: {
          img: require("@/assets/images/arrow_left.png"),
          imgGray: require("@/assets/images/arrow_left_gray.png"),
        },
      };
    },
    props: {
      id: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    created() {
      this.init();
    },
    mounted() {
      this.wxShare();
    },
    methods: {
      init() {
        this.getFilm();
      },
      // 微信分享
      wxShare() {
        const url = encodeURIComponent(location.href.split("#")[0]);
        const data = { url };
        getSignature(data)
          .then((res) => {
            console.log("wxShare", res);
            wx.config({
              debug: false,
              appId: res.appId,
              timestamp: res.timestamp,
              nonceStr: res.nonceStr,
              signature: res.signature,
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                "onMenuShareQQ",
                "onMenuShareWeibo",
                "onMenuShareQZone",
              ],
            });
            //分享配置
            let shareLink = location.href;
            let hashBefore = shareLink.split("#")[0];
            let path = shareLink.split("#")[1];
            let linkUrl = hashBefore.includes("?")
              ? `${hashBefore}&shareUrl=${path}`
              : `${hashBefore}?shareUrl=${path}`;
            console.log(linkUrl);

            let linkTitle = this.film.Name;
            let linkContent = this.film.Introduction;

            if (linkContent == "") {
              linkContent = "我分享了【" + linkTitle + "】";
            }
            if (linkContent.length > 39) {
              linkContent = linkContent.substring(0, 39);
            }

            wx.ready(function () {
              const shareData = {
                title: linkTitle,
                desc: linkContent,
                link: linkUrl,
                imgUrl:
                  "https://dyz.zjfilm.cn/dist/test/static/img/3.f383314d.png",
              };
              wx.onMenuShareTimeline(shareData);
              wx.onMenuShareAppMessage(shareData);
              wx.onMenuShareQQ(shareData);
              wx.onMenuShareWeibo(shareData);
              wx.onMenuShareQZone(shareData);
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      },
      getFilm() {
        const data = { id: this.id };
        getFilm(data).then((res) => {
          let result = res;
          // 图片处理
          if (result.ImgUrl.length > 0) {
            result.ImgUrl = result.ImgUrl[0].url;
          } else {
            result.ImgUrl = "";
          }
          this.film = result;
          console.log("FilmDetails:", this.film);
          this.getFilmList();
        });
      },
      getFilmList() {
        let data = { category: this.film.Category };
        getFilmList(data).then((res) => {
          let result = res.result;
          console.log("FilmList:", result);
          let prevIndex, nextIndex;
          result.forEach((item, index) => {
            if (item.Id == this.id) {
              prevIndex = index - 1;
              nextIndex = index + 1;
            }
          });

          if (prevIndex >= 0) {
            this.prevId = result[prevIndex].Id;
          } else {
            this.prevId = -1;
          }

          if (nextIndex < result.length) {
            this.nextId = result[nextIndex].Id;
          } else {
            this.nextId = -1;
          }
          console.log(this.prevId, "--", this.nextId);
        });
      },
      gotoFilm(id) {
        if (id > -1)
          this.$router.push({
            path: "/zy/mvdetail",
            query: {
              id,
            },
          });
      },
    },
  };
</script>
<style scoped>
  .film-container {
    position: relative;
    padding-bottom: 12vh;
  }
  .image-cover {
    height: 55vh;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    position: relative;
  }
  .image-cover::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #1d111f;
    opacity: 0.7;
    z-index: 0;
  }
  .film-image {
    height: 50vh;
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0 0 1.5rem rgb(29 17 31 / 70%);
  }
  .film-image img {
    display: block;
    height: 100%;
  }
  .film-details {
    position: relative;
  }
  .film-title {
    background: rgba(0, 0, 0, 0.85);
    padding: 1rem;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .film-content {
    font-size: 16px;
    line-height: 32px;
    padding: 16px;
    padding-bottom: 36px;
  }
  .turn-page {
    position: fixed;
    width: 100%;
    height: 8vh;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .page-item {
    position: relative;
    width: 34%;
    height: 100%;
    margin: 2vh 33%;
    color: #fff;
  }
  .page-item::after {
    content: "";
    height: 0.5vh;
    width: 100%;
    display: block;
    position: relative;
    top: 60%;
    background: #fff;
    border-radius: 10px;
  }
  .page-item img {
    height: 3vh;
  }
  [v-cloak] {
    display: none;
  }
</style>
