<template>
  <div id="backtop">
    <transition name="el-fade-in">
      <div class="backtop-item" @click="backtop" v-show="totop">
        <div class="backtop-backimg"></div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "BackTop",
    data() {
      return {
        scrollTop: 0,
        totop: false,
        screenHeight: document.documentElement.clientHeight,
      };
    },
    mounted() {
      window.addEventListener("scroll", this.scrolltotop, true);
    },
    destroyed() {
      window.removeEventListener("scroll", this.scrolltotop, true);
    },
    methods: {
      scrolltotop() {
        this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        // console.log(this.scrollTop + "-" + this.screenHeight);

        if (this.scrollTop > 200) {
          this.totop = true;
        } else {
          this.totop = false;
        }
      },
      backtop() {
        let back = setInterval(() => {
          if (document.body.scrollTop || document.documentElement.scrollTop) {
            document.body.scrollTop -= 50;
            document.documentElement.scrollTop -= 50;
          } else {
            clearInterval(back);
          }
        });
      },
    },
  };
</script>

<style scoped>
  .backtop-item {
    position: fixed;
    width: 40px;
    height: 40px;
    line-height: 40px;
    opacity: 0.9;
    right: 10px;
    bottom: 30px;
    z-index: 50;
    border-radius: 50%;
    background-color: #fec93b;
  }
  .backtop-backimg {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 0;
    background-image: url("../../assets/images/news/totop.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
</style>
