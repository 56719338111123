<template>
  <div class="zForm">
    <div :style="divstyle">
      <el-form
        ref="form"
        :model="form"
        label-position="top"
        :label-width="labelWidth"
        :rules="rule"
      >
        <div v-for="item in formConfig" :key="item.field">
          <el-form-item
            v-if="item.type === 'input'"
            :label="item.label"
            style="line-height: 20px"
            :prop="item.field"
          >
            <el-input v-model="form[item.field]" v-bind="item.props"></el-input>
            <div class="red-text" v-html="item.props.suffix" />
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'number'"
            :label="item.label"
            :prop="item.field"
          >
            <el-input
              v-model="form[item.field]"
              v-bind="item.props"
              type="number"
            ></el-input>
            <!-- <el-input
              v-model="form[item.field]"
              v-bind="item.props"
              oninput="value = value.replace(/[^\d]/g, '')"
            ></el-input> -->
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'yzm'"
            :label="item.label"
            :prop="item.field"
          >
            <el-input
              v-bind="item.props"
              v-model.trim="form[item.field]"
              placeholder="请输入手机验证码"
            ></el-input>
            <el-button
              :disabled="isGetPhone"
              class="phone-code"
              type="primary"
              @click="getPhoneCode"
            >
              {{ phoneCode }}
            </el-button>
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'date'"
            :label="item.label"
            :prop="item.field"
          >
            <el-date-picker
              v-bind="item.props"
              v-model="form[item.field]"
              style="width: 100%"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :append-to-body="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'textarea'"
            :label="item.label"
            :prop="item.field"
          >
            <el-input
              type="textarea"
              v-model="form[item.field]"
              v-bind="{
                maxlength: item.props.maxlength || 500,
                'show-word-limit':
                  item.props['show-word-limit'] != undefined
                    ? item.props['show-word-limit']
                    : true,
                autosize: item.props.autosize || { minRows: 4, maxRows: 8 },
                ...item.props,
              }"
            ></el-input>
            <div class="gray-text" v-html="item.props.suffix" />
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'photo'"
            :label="item.label"
            :prop="item.field"
          >
            <UploadPhoto
              :ref="item.field"
              :fileListName="{
                name: item.field,
              }"
              @handlePreview="handlePictureCardPreview"
              @upsuccess="updatephotoFile"
              :accept="item.props.accept"
            />
            <div class="gray-text" v-html="item.props.suffix" />
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'file'"
            :label="item.label"
            :prop="item.field"
          >
            <UploadFile
              :ref="item.field"
              :fileListName="item.field"
              @upsuccess="updateFile"
              v-bind="item.props"
            />
            <div class="gray-text" v-html="item.props.suffix" />
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'area'"
            :label="item.label"
            :prop="item.field"
          >
            <el-cascader
              placeholder="请选择 省 / 市 / 区"
              filterable
              style="width: 100%"
              v-model="form[item.field]"
              :options="options"
              :props="{ value: 'name', label: 'name' }"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'switch'"
            :label="item.label"
            :prop="item.field"
          >
            <el-switch
              v-model="form[item.field]"
              v-bind="item.props"
            ></el-switch>
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'checkbox'"
            :label="item.label"
            :prop="item.field"
          >
            <el-checkbox-group v-model="form[item.field]" v-bind="item.props">
              <el-checkbox
                v-for="lb in item.options"
                :key="lb.label"
                :label="lb.label"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'radio'"
            :label="item.label"
            :prop="item.field"
          >
            <el-radio-group v-model="form[item.field]" v-bind="item.props">
              <el-radio
                v-for="lb in item.options"
                :key="lb.label"
                :label="lb.label"
              ></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-else-if="item.type === 'select'"
            :label="item.label"
            :prop="item.field"
          >
            <el-select v-model="form[item.field]" v-bind="item.props">
              <el-option
                v-for="lb in item.options"
                :key="lb.label"
                :label="lb.label"
                :value="lb.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-dialog :visible.sync="dyshow" append-to-body title="预览">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
  import UploadPhoto from "./baseComp/photoUpload.vue";
  import UploadFile from "./baseComp/UploadFile.vue";
  import Area from "@/utils/area.js";
  import config from "./config/config.js";

  let isPhone = config.isPhone;
  let isEmail = config.isEmail;
  let isIdCard = config.isIdCard;

  const validatePhone = (rule, value, callback) => {
    if (!isPhone(value)) {
      callback("请输入正确的手机号,只能是数字");
    } else {
      callback();
    }
  };
  let curPhoneCode = "123456";
  const validatePhoneCode = (rule, value, callback) => {
    console.log(value, curPhoneCode);
    if (!(value === curPhoneCode)) {
      callback("验证码错误");
    } else {
      callback();
    }
  };
  const validateEmail = (rule, value, callback) => {
    if (!isEmail(value)) {
      callback("邮箱格式不正确");
    } else {
      callback();
    }
  };
  const validateIdCard = (rule, value, callback) => {
    if (!isIdCard(value)) {
      callback("身份证号格式不正确");
    } else {
      callback();
    }
  };
  export default {
    name: "zForm",
    props: {
      formConfig: {
        type: Array,
        default: () => [],
      },
      labelWidth: {
        type: String,
        default: () => "130px",
      },
      divstyle: {
        type: String,
        default: () => "",
      },
    },
    components: {
      UploadPhoto,
      UploadFile,
    },
    data() {
      return {
        btnloading: false,
        getPhoneInterval: null,
        isGetPhone: false,
        options: Area,

        dialogImageUrl: "",
        dyshow: false,
        phoneCode: "获取验证码",
        form: {},
        rule: {},
      };
    },
    beforeDestroy() {
      this.getPhoneInterval = null;
      clearInterval(this.getPhoneInterval);
    },

    methods: {
      initData() {
        let form = {};
        let rule = {};
        this.formConfig.forEach((item) => {
          let initValue = "";

          let ruleinitValue = [
            {
              required: true,
              message: item.rule
                ? item.rule.message || `请输入${item.label}`
                : `请输入${item.label}`,
              trigger: "blur",
            },
          ];
          switch (item.type) {
            case "switch":
              initValue = false;

              break;
            case "date":
              initValue = "";
              ruleinitValue = [
                {
                  trigger: "change",
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请选择${item.label}`
                    : `请选择${item.label}`,
                },
              ];
              break;
            case "file":
              initValue = [];
              ruleinitValue = [
                {
                  type: "array",
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请上传${item.label}`
                    : `请上传${item.label}`,
                },
              ];
              break;
            case "photo":
              initValue = [];
              ruleinitValue = [
                {
                  type: "array",
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请上传${item.label}`
                    : `请上传${item.label}`,
                },
              ];
              break;
            case "area":
              initValue = [];
              ruleinitValue = [
                {
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请选择${item.label}`
                    : `请选择${item.label}`,
                  trigger: "change",
                },
              ];
              break;
            case "select":
              initValue = "";
              ruleinitValue = [
                {
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请选择${item.label}`
                    : `请选择${item.label}`,
                  trigger: "change",
                },
              ];
              break;
            case "radio":
              initValue = null;
              ruleinitValue = [
                {
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请选择${item.label}`
                    : `请选择${item.label}`,
                  trigger: "change",
                },
              ];
              break;
            case "checkbox":
              initValue = [];
              ruleinitValue = [
                {
                  type: "array",
                  required: true,
                  message: item.rule
                    ? item.rule.message || `请选择至少一项${item.label}`
                    : `请选择至少一项${item.label}`,
                  trigger: "change",
                },
              ];
              break;
            default:
              break;
          }

          form[item.field] = initValue;

          if (item.rule) {
            switch (item.rule.validator) {
              case "Phone":
                ruleinitValue.push({
                  validator: validatePhone,
                  trigger: "blur",
                });
                break;
              case "PhoneCode":
                ruleinitValue.push({
                  validator: validatePhoneCode,
                  trigger: "blur",
                });
                break;
              case "Email":
                ruleinitValue.push({
                  validator: validateEmail,
                  trigger: "blur",
                });
                break;
              case "IdCard":
                ruleinitValue.push({
                  validator: validateIdCard,
                  trigger: "blur",
                });
                break;
              default:
                break;
            }
            rule[item.field] = ruleinitValue;
          }
        });

        this.form = form;
        this.rule = rule;
      },
      updateFile(fileListName, data) {
        this.form[fileListName] = data;
        this.$refs.form.clearValidate([fileListName]);
      },
      updatephotoFile(fileListName, data) {
        this.form[fileListName.name] = data;
        this.$refs.form.clearValidate([fileListName.name]);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dyshow = true;
      },
      getPhoneCode() {
        if (!isPhone(this.form.Phone)) {
          this.$refs["form"].validateField("Phone");
          return;
        }
        this.isGetPhone = true;
        let n = 60;
        // 发送手机验 证码
        console.log(this.form);
        this.getPhoneInterval = setInterval(() => {
          if (n > 0) {
            n--;
            this.phoneCode = "获取验证码 " + n + "s";
            console.log(this.phoneCode);
          } else {
            clearInterval(this.getPhoneInterval);
            this.phoneCode = "获取验证码";
            this.getPhoneInterval = null;
            this.isGetPhone = false;
          }
        }, 1000);
      },
      onSubmit(func) {
        console.log(this.form);
        this.$refs["form"].validate((valid, errobj) => {
          if (valid) {
            func();
          } else {
            let errmessage = [];
            for (let attr of Object.keys(errobj)) {
              console.log();
              errmessage.push(errobj[attr][0].message);
            }
            let str = errmessage.join("<br/><br/>");
            this.$message({
              type: "error",
              dangerouslyUseHTMLString: true,
              message: `<strong>${str}</strong>`,
            });
          }
        });
      },
      resetForm() {
        this.$refs.form.resetFields();
        let photoList = this.formConfig.filter((item) => item.type === "photo");
        if (photoList.length > 0) {
          photoList.forEach((item) => {
            this.$refs[item.field][0].fileList = [];
          });
        }
        let fileList = this.formConfig.filter((item) => item.type === "file");
        if (fileList.length > 0) {
          fileList.forEach((item) => {
            this.$refs[item.field][0].fileList = [];
          });
        }
      },
      resetLoading() {
        this.btnloading = false;
      },
    },
    watch: {
      formConfig() {
        this.initData();
      },
    },
    created() {
      this.initData();
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},

    destroyed() {},
    activated() {},
  };
</script>
<style lang="postcss">
  .zForm {
    background: #fff;
  }
  .yzm:hover {
    font-weight: bold;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .phone-code {
    position: absolute;

    top: 5px;
    right: 5px;
    width: 120px;
    height: 32px;
    padding: 0;
    line-height: 32px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
  }
  .gray-text {
    color: rgba(128, 128, 128, 80%);
    margin-top: 8px;
    line-height: 1.6;
    font-size: 10px;
    font-family: ali;
    margin-bottom: 10px;
  }
  .red-text {
    font-weight: bold;
    color: rgba(255, 0, 0, 80%);
    margin-top: 8px;
    line-height: 1.6;
    font-size: 10px;
    font-family: ali;
    margin-bottom: 10px;
  }
  /* input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  } */
  /* .el-form-item.is-error .el-upload--picture-card {
    border-color: #f56c6c;
  } */
</style>
