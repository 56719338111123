<template>
  <div id="news-details" v-cloak>
    <div class="news-container">
      <div>
        <div class="news-title">
          {{ news.inTitle }}
        </div>
        <div class="news-padding">
          <div class="news-subtitle">{{ news.source }}</div>
          <div v-html="news.content" class="news-content"></div>
        </div>
      </div>
    </div>
    <div v-if="isTurnPage" class="turn-page-container">
      <div id="prev-page" class="turn-page-item" @click="gotoPrev()">
        <p>上一篇：{{ prevPage == "" ? "无" : prevPage.outTitle }}</p>
      </div>
      <div id="next-page" class="turn-page-item" @click="gotoNext()">
        <p>下一篇：{{ nextPage == "" ? "无" : nextPage.outTitle }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import wx from "weixin-js-sdk";
  import { getNews, getList, getSignature } from "@/network/index";
  export default {
    name: "NewsDetails",
    data() {
      return {
        news: {},
        prevPage: {},
        nextPage: {},
      };
    },
    props: {
      newsId: {
        type: Number,
        // default设置默认值
        default: 0,
        //required：true表示在使用组件时必须要像该prop传递值
        required: true,
      },
      type: {
        type: String,
      },
      auId: {
        type: Number,
      },
    },
    computed: {
      isTurnPage() {
        return (
          this.type == "新闻动态" ||
          this.type == "往届回顾" ||
          // this.type.indexOf("活动") != -1
          this.type == "活动"
        );
      },
    },
    created() {
      this.init();
    },
    mounted() {
      this.wxShare();
    },

    methods: {
      init() {
        this.getNewsData();
        if (this.isTurnPage) this.getNewsList();
      },
      // 微信分享
      wxShare() {
        const url = encodeURIComponent(location.href.split("#")[0]);
        const data = { url };
        getSignature(data)
          .then((res) => {
            console.log("wxShare", res);
            wx.config({
              debug: false,
              appId: res.appId,
              timestamp: res.timestamp,
              nonceStr: res.nonceStr,
              signature: res.signature,
              jsApiList: [
                "onMenuShareTimeline",
                "onMenuShareAppMessage",
                "onMenuShareQQ",
                "onMenuShareWeibo",
                "onMenuShareQZone",
              ],
            });
            //分享配置
            let shareLink = location.href;
            let hashBefore = shareLink.split("#")[0];
            let path = shareLink.split("#")[1];
            let linkUrl = hashBefore.includes("?")
              ? `${hashBefore}&shareUrl=${path}`
              : `${hashBefore}?shareUrl=${path}`;
            console.log(linkUrl);
            console.log(this.news.inTitle);

            let linkTitle = this.news.inTitle;
            let linkContent = this.news.titleContent;

            if (linkContent == "") {
              linkContent = "我分享了【" + linkTitle + "】";
            }
            if (linkContent.length > 39) {
              linkContent = linkContent.substring(0, 39);
            }

            if (linkTitle.length > 28) {
              linkTitle = linkTitle.substring(0, 28);
            }

            wx.ready(function () {
              const shareData = {
                title: linkTitle,
                desc: linkContent,
                link: linkUrl,
                imgUrl:
                  "https://dyz.zjfilm.cn/dist/test/static/img/3.f383314d.png",
              };
              wx.onMenuShareTimeline(shareData);
              wx.onMenuShareAppMessage(shareData);
              wx.onMenuShareQQ(shareData);
              wx.onMenuShareWeibo(shareData);
              wx.onMenuShareQZone(shareData);
            });
          })
          .catch(function (err) {
            console.log(err);
          });
      },
      getNewsData() {
        let data = { id: this.newsId };
        getNews(data).then((res) => {
          let result = res;
          console.log("请求结果", result);
          this.news = result;
        });
      },
      getNewsList() {
        let data = { category: this.type };
        getList(data).then((res) => {
          let result = res.result;
          console.log("请求列表结果", result);

          let prevIndex, nextIndex;
          result.forEach((item, index) => {
            if (item.id == this.auId) {
              prevIndex = index - 1;
              nextIndex = index + 1;
            }
          });
          // 上一篇
          if (prevIndex >= 0) {
            this.prevPage = result[prevIndex];
          } else {
            this.prevPage = "";
          }
          console.log("上一篇", this.prevPage);
          // 下一篇
          if (nextIndex < result.length) {
            this.nextPage = result[nextIndex];
          } else {
            this.nextPage = "";
          }
          console.log("下一篇", this.nextPage);
        });
      },
      // 跳转链接
      gotoPrev() {
        const item = this.prevPage;
        if (item != "") {
          if (item.ifout == true) {
            const url = item.url;
            if (url == null || url == "") {
              console.log("未填写链接");
              return;
            }
            const path = url.split("dyz.zjfilm.cn/#")[1];
            if (!path) {
              console.log(url);
              window.location.href = url;
              return;
            }
            let newUrl = window.location.href.split("#")[0] + "#" + path;
            if (newUrl.indexOf("?") != -1) {
              newUrl = newUrl + "&type=" + item.category + "&auId=" + item.id;
            }
            console.log(newUrl);
            window.location.href = newUrl;
          } else {
            this.$router.push({
              path: "/news",
              query: {
                id: item.id,
                type: item.category,
                auId: item.id,
              },
            });
          }
        }
      },
      gotoNext() {
        const item = this.nextPage;
        if (item != "") {
          if (item.ifout == true) {
            const url = item.url;
            if (url == null || url == "") {
              console.log("未填写链接");
              return;
            }
            const path = url.split("dyz.zjfilm.cn/#")[1];
            if (!path) {
              console.log(url);
              window.location.href = url;
              return;
            }
            let newUrl = window.location.href.split("#")[0] + "#" + path;
            if (newUrl.indexOf("?") != -1) {
              newUrl = newUrl + "&type=" + item.category + "&auId=" + item.id;
            }
            console.log(newUrl);
            window.location.href = newUrl;
          } else {
            this.$router.push({
              path: "/news",
              query: {
                id: item.id,
                type: item.category,
                auId: item.id,
              },
            });
          }
        }
      },
    },
  };
</script>

<style scoped>
  .turn-page-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
  }
  .turn-page-item {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #eee;
    margin-bottom: 10px;
  }
  .turn-page-item p {
    width: 100%;
    text-align: left;
    color: #000;
    box-sizing: border-box;
    line-height: 24px;
    font-size: 14px;
    padding: 5px 10px;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
</style>
