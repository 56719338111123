<template>
  <!-- :headers="headers" -->
  <el-upload
    class="upload-demo"
    :action="action"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    list-type="picture-card"
    :multiple="false"
    :accept="accept"
    :limit="3"
    :on-exceed="handleExceed"
    :file-list="fileList"
    :on-success="handleSuccess"
    :on-error="handleError"
    :on-progress="handleProgress"
  >
    <i class="el-icon-plus"></i>
    <div slot="tip" class="el-upload__tip">
      {{ tip }}
    </div>
  </el-upload>
</template>
<script>
import config from "../config/config.js";
export default {
  name: "PhotoUpload",
  props: {
    tip: {
      type: String,
      default: () => "",
    },
    accept: {
      type: String,
      default: () => ".jpg,.jpeg,.png",
    },
    fileListName: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      action: config.uploadApi,

      ifuploading: false,
      fileList: [],
    };
  },
  created() {},
  methods: {
    getList() {
      console.log("getList", this.fileList);
    },
    handleRemove(file, fileList) {
      this.ifuploading = false;
      console.log(file, fileList, this.fileList);
      this.fileList = fileList;
      this.$emit("upsuccess", this.fileListName, fileList);
    },

    handlePreview(file) {
      console.log(file);
      this.$emit("handlePreview", file);
    },
    handleSuccess(response, file, fileList) {
      this.ifuploading = false;
      this.fileList = fileList;
      console.log(response, file, fileList);
      this.$emit("upsuccess", this.fileListName, fileList);
    },
    handleError(err, file, fileList) {
      this.ifuploading = false;
      console.log(err, file, fileList);
    },
    handleProgress(event, file, fileList) {
      this.ifuploading = true;
      console.log(event, file, fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`)
    },
  },
};
</script>
