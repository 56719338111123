// const uploadApi = `http://47.99.47.243:8484/zjfilm/PostData/UploadFile`; // 文件/图片上传服务器api地址
const uploadApi = `https://www.dyz.zjfilm.cn/zjfilm/PostData/UploadFile`; // 文件/图片上传服务器api地址

// 系统参数配置
export default {
  uploadApi, // 文件/图片上传服务器api地址

  isPhone: (value) => {
    const reg = /^1\d{10}$/;
    return reg.test(value);
  },
  isEmail: (value) => {
    const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(value);
  },
  isIdCard: (value) => {
    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return reg.test(value);
  },
};
