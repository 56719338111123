<template>
  <div id="project-submission">
    <div class="bmtd-container">
      <div id="con" class="con"></div>

      <div
        style="
          position: relative;
          margin: 0 auto;
          margin-top: -150px;
          width: 100%;
          text-align: center;
          padding: 32px 0;
        "
      >
        <el-checkbox v-model="checked" style="color: #fff">
          我已仔细阅读并同意「2024浙江青年电影周创投章程」
        </el-checkbox>
        <br />
        <br />
        <el-button
          @click="jbclick"
          class="small-button"
          style="cursor: pointer"
          type="primary"
          size="small"
        >
          点击报名
        </el-button>
      </div>

      <z-dialog
        ref="zDialog"
        width="90%"
        :center="true"
        :modalclose="false"
        @close="handleClose"
      >
        <div style="margin-bottom: 28px" />
        <div
          v-show="page === 1"
          style="width: 100%; margin: 0 auto; text-align: center"
        >
          <el-radio-group v-model.number="num1" size="small">
            <el-radio-button
              v-for="(item, mainIndex) in titleList"
              :key="mainIndex"
              :label="item.num"
            >
              {{ item.title }}
            </el-radio-button>
          </el-radio-group>

          <el-row style="margin-top: 60px; text-align: center">
            <el-col :span="24">
              <el-button
                @click="sdclick"
                :disabled="num1 === 0"
                type="primary"
                size="small"
                class="small-button"
              >
                下一步
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div
          v-show="page === 2"
          style="width: 100%; margin: 0 auto; text-align: center"
        >
          <el-radio-group v-model.number="num2" size="small">
            <el-radio-button
              v-for="(item, subIndex) in subTitleList"
              :key="subIndex"
              :label="item.num"
            >
              {{ item.title }}
            </el-radio-button>
          </el-radio-group>

          <el-row style="margin-top: 60px; text-align: center">
            <el-col :span="12">
              <el-button
                @click="backclick"
                type="danger"
                size="small"
                class="small-button"
              >
                返回
              </el-button>
            </el-col>
            <el-col :span="12">
              <el-button
                @click="xmclick"
                :disabled="num2 === -1"
                type="primary"
                size="small"
                class="small-button"
              >
                下一步
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div v-show="page === 3" style="width: 100%; margin: 0 auto">
          <z-form ref="zForm" v-bind="options"></z-form>
          <div>
            <el-row style="margin-top: 60px; text-align: center">
              <el-col :span="12">
                <el-button
                  @click="backclick"
                  type="danger"
                  size="small"
                  class="small-button"
                >
                  返回
                </el-button>
              </el-col>

              <el-col :span="12">
                <el-button
                  :loading="btnloading"
                  type="primary"
                  @click="mainclick"
                  size="small"
                  class="small-button"
                >
                  下一步
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-show="page === 4" style="width: 100%; margin: 0 auto">
          <p class="small-title">申报人信息</p>
          <z-form ref="sbForm" v-bind="sbOptions"></z-form>
          <el-divider />
          <p class="small-title">导演信息</p>
          <z-form ref="dyForm" v-bind="dyOptions"></z-form>
          <el-divider />
          <p class="small-title">制片人信息（选填）</p>
          <z-form ref="zpForm" v-bind="zpOptions"></z-form>
          <el-divider />
          <p class="small-title">其他人信息（选填）</p>
          <z-form ref="qtForm" v-bind="qtOptions"></z-form>
          <el-divider />
          <p class="small-title">出品公司（选填）</p>
          <z-form ref="gsForm" v-bind="gsOptions"></z-form>
          <el-divider />
          <p class="small-title">授权许可</p>
          <div
            style="
              font-weight: bold;
              line-height: 24px;
              font-size: 10px;
              font-family: ali;
            "
          >
            <p>1.我已完整阅读并了解2024浙江国际青年电影周创投章程。</p>
            <el-checkbox v-model="checked1">是</el-checkbox>
            <p>2.我完全同意并将履行2024浙江国际青年电影周创投章程所述事宜。</p>
            <el-checkbox v-model="checked2">是</el-checkbox>
            <p>
              3.我确认报名者为所提交电影计划及影片或资料素材的著作权的合法权利人或授权代表人，确保所提交的项目及相关信息均为原创，不损害任何第三方合法权益，不违反中华人民共和国相关法律条款和公序良俗。2024浙江国际青年电影周组委会不承担任何法律责任。如报名项目存在包括但不限于著作权侵权等行为，组委会有权拒绝该项目及报名者入选，并要求报名者赔偿因此给电影展造成的全部损失。
            </p>
            <el-checkbox v-model="checked3">是</el-checkbox>
            <p>
              4.我允许并授2024浙江国际青年电影展组委会使用报名项目信息（包括但不限于电影计划文字资料、概念海报、概念视频、相关人员照片、幕后创作花絮）用于影展手册、官方及协作网站，以及其他用以宣传的文字、音、视频资料中。
            </p>
            <el-checkbox v-model="checked4">是</el-checkbox>
            <p>5.我保证以上所填内容属实。</p>
            <el-checkbox v-model="checked5">是</el-checkbox>
          </div>
          <div>
            <el-row style="margin-top: 60px; text-align: center">
              <el-col :span="12">
                <el-button
                  @click="backclick"
                  type="danger"
                  size="small"
                  class="small-button"
                >
                  返回
                </el-button>
              </el-col>
              <el-col :span="12">
                <el-button
                  @click="onSubmit"
                  :disabled="!isAgree"
                  type="primary"
                  size="small"
                  class="small-button"
                >
                  确认报名
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-show="page === 5" style="width: 100%; margin: 0 auto">
          <div style="text-align: center">
            <img
              style="height: 100px; width: 100px"
              :src="require(`../../../assets/succes.png`)"
              alt=""
              srcset=""
            />
          </div>
          <br />

          <div
            style="
              text-align: center;
              font-size: 12px;
              margin-bottom: 30px;
              margin: 0 auto;
            "
          >
            <strong>
              您已成功报名2024浙江国际青年电影展创投，后续入选结果请关注官方渠道及邮件通知
            </strong>
          </div>
          <br />
        </div>
      </z-dialog>
    </div>
  </div>
</template>
<script>
  import $ from "jquery";
  import zForm from "@/components/zForm";
  import zDialog from "@/components/zDialog";
  import { postSubmit } from "@/network/index";
  export default {
    name: "ProjectSubmission",
    components: { zForm, zDialog },
    computed: {
      isAgree() {
        if (
          this.checked1 &&
          this.checked2 &&
          this.checked3 &&
          this.checked4 &&
          this.checked5
        )
          return true;
        else return false;
      },
    },
    data() {
      return {
        btnloading: false,
        page: 1,
        title: "创投赛道选择",
        mtitle: "",
        stitle: "",
        joinType: 0,
        num1: 0,
        num2: -1,
        titleList: [
          { title: "主赛道", num: 10 },
          { title: "千禧赛道", num: 20 },
          { title: "浙江赛道", num: 30 },
        ],
        subTitleList: [
          { title: "创作中项目（IDP）", num: 0 },
          { title: "制作中项目（WIP）", num: 1 },
        ],
        checked: false,
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
        checked5: false,
        dialogVisible: false,
        dialogImageUrl: "",
        formData: {},
        IDP: [
          {
            field: "DirectorInfo",
            label: "导演简历",
            type: "file",
            rule: {},
            props: { suffix: "请上传pdf文件", accept: ".pdf" },
          },
          {
            field: "ProducerInfo",
            label: "制片人简历",
            type: "file",
            props: { suffix: "请上传pdf文件", accept: ".pdf" },
          },
          {
            field: "ProjectName",
            label: "项目名称",
            type: "textarea",
            rule: {},
            props: {},
          },
          {
            field: "Introduction",
            label: "项目单句介绍",
            type: "textarea",
            rule: {},
            props: {
              maxlength: 40,
            },
          },
          {
            field: "Story",
            label: "故事梗概",
            type: "textarea",
            rule: {},
            props: {
              maxlength: 500,
            },
          },
          {
            field: "DirectorProjectDescription",
            label: "导演项目阐述",
            type: "textarea",
            rule: {},
            props: {
              maxlength: 200,
            },
          },
          {
            field: "ProducerProjectDescription",
            label: "制片人项目阐述",
            type: "textarea",
            rule: {},
            props: { maxlength: 200 },
          },
          {
            field: "LibrettoUrl",
            label: "剧本大纲",
            type: "file",
            rule: {},
            props: { suffix: "请上传pdf文件，大纲约3000字", accept: ".pdf" },
          },
          {
            field: "FifteenScenesUrl",
            label: "前十五场戏剧本",
            type: "file",
            rule: {},
            props: {
              suffix: "请上传pdf文件，入选30强需提供完整剧本",
              accept: ".pdf",
            },
          },
          {
            field: "ProjectPlanUrl",
            label: "项目策划方案",
            type: "file",
            rule: {},
            props: {
              suffix:
                "请上传ppt形式的pdf文件，含详细的制作预算表、时间表等开发计划",
              accept: ".pdf",
            },
          },
          {
            field: "CompleteScriptUrl",
            label: "完整剧本",
            type: "file",
            props: {
              suffix: "请上传pdf文件，入选30强必须提交",
              accept: ".pdf",
            },
          },
          {
            field: "ConceptPosterUrl",
            label: "概念海报",
            type: "file",
            props: {
              suffix: "请上传jpg或jpeg图片，入选30强必须提交",
              accept: ".jpg,.jpeg",
            },
          },
          {
            field: "SamplePieceUrl",
            label: "样片/概念短片链接",
            type: "textarea",
            props: {},
          },
        ],
        WIP: [
          {
            field: "DirectorInfo",
            label: "导演简历",
            type: "file",
            rule: {},
            props: { suffix: "请上传pdf文件", accept: ".pdf" },
          },
          {
            field: "ProducerInfo",
            label: "制片人简历",
            type: "file",
            props: { suffix: "请上传pdf文件", accept: ".pdf" },
          },
          {
            field: "ProjectName",
            label: "项目名称",
            type: "textarea",
            rule: {},
            props: {},
          },
          {
            field: "Introduction",
            label: "项目单句介绍",
            type: "textarea",
            rule: {},
            props: {
              maxlength: 40,
            },
          },
          {
            field: "LibrettoUrl",
            label: "剧本大纲",
            type: "file",
            rule: {},
            props: { suffix: "请上传pdf文件，大纲约3000字", accept: ".pdf" },
          },
          {
            field: "ProjectPlanUrl",
            label: "项目策划方案",
            type: "file",
            rule: {},
            props: {
              suffix:
                "请上传ppt形式的pdf文件，内容需包含故事梗概、导演项目阐述、制片人项目阐述、详细的制作预算表、时间表等开发计划",
              accept: ".pdf",
            },
          },

          {
            field: "CompleteScriptUrl",
            label: "完整剧本",
            type: "file",
            rule: {},
            props: { suffix: "请上传pdf文件", accept: ".pdf" },
          },
          {
            field: "InitialCut",
            label: "初剪版本片段",
            type: "textarea",
            rule: {},
            props: {
              placeholder:
                "20-45分钟初剪版本片段，须提供可加密观看的线上播放链接或下载链接（优先百度云盘），于线上一并提交。",
              suffix: `
            <p>请确保链接及密码有效时间不早于2024年8月30日，粗剪版本片段需符合以下标准：</p>
            <p>1.视频文件分辨率须在1280×720及以上，格式需为MOV或MP4；</p>
            <p>2.文件大小不超过5GB；</p>
            <p>3.如需添加水印，可于不影响观影前提下，添加水印字样；</p>
            <p>4.影片需有中文字幕。</p>
            `,
              maxlength: 500,
            },
          },
          {
            field: "ProjectProgress",
            label: "目前项目进度概述",
            type: "textarea",
            rule: {},
            props: {
              suffix: "中文300字内",
              maxlength: 300,
            },
          },
          {
            field: "ConceptPosterUrl",
            label: "概念海报",
            type: "file",
            props: {
              suffix: "请上传jpg或jpeg图片，入选30强必须提交",
              accept: ".jpg,.jpeg",
            },
          },
          {
            field: "ProjectApprovalUrl",
            label: "立项许可",
            type: "file",
            props: {
              suffix: "请上传pdf文件，入选30强必须提交",
              accept: ".pdf",
            },
          },
        ],
        zj: {
          field: "ZhejiangElements",
          label: "浙江元素阐述",
          type: "textarea",
          rule: {},
          props: {
            suffix:
              "申报主创(导演、编剧、制片人其一即可)为浙江籍；或申报项目中包含浙江元素（包括但不限于未来拍摄地、故事发生地、展现浙江风土人情",
            maxlength: 500,
          },
        },

        qx: {
          field: "DirectorIDCard",
          label: "主创身份证明",
          type: "photo",
          rule: {},
          props: {
            suffix: "身份证/护照照片",
          },
        },

        options: {
          formConfig: [],
        },
        // 申报人信息
        sbOptions: {
          formConfig: [
            {
              field: "DeclarerPosition",
              label: "申报人担任项目职务",
              type: "input",
              rule: {},
              props: {
                placeholder:
                  "（导演、联合导演、编剧、制片人、联合制片人、其他）",
                lineheight: "20px",
              },
            },
            {
              field: "DeclarerName",
              label: "申报人姓名",
              type: "input",
              rule: {},
              props: {},
            },
            {
              field: "DeclarerMail",
              label: "电子邮箱",
              type: "input",
              rule: { validator: "Email" },
              props: {
                suffix:
                  "此联系邮箱将作为后续收取官方通知的地址，请仔细核对填写",
              },
            },
            {
              field: "DeclarerTel",
              label: "联系电话",
              type: "number",
              rule: { message: "请输入手机号", validator: "Phone" },
              props: {},
            },
          ],
        },
        // 导演信息
        dyOptions: {
          formConfig: [
            {
              field: "DirectorName",
              label: "导演姓名",
              type: "input",
              rule: {},
              props: {},
            },

            {
              field: "DirectorGender",
              label: "性别",
              type: "radio",
              rule: {},
              options: [{ label: "男" }, { label: "女" }],
              props: {},
            },
            {
              field: "DirectorBirthString",
              label: "出生日期",
              type: "date",
              rule: {},
              props: {},
            },
            {
              field: "DirectorCountry",
              label: "国籍",
              type: "input",
              rule: {},
              props: {},
            },
            {
              field: "DirectorMail",
              label: "电子邮箱",
              type: "input",
              rule: { validator: "Email" },
              props: {},
            },
            {
              field: "DirectorTel",
              label: "联系电话",
              type: "number",
              rule: { message: "请输入手机号", validator: "Phone" },
              props: {},
            },
          ],
        },
        // 制片人信息
        zpOptions: {
          formConfig: [
            {
              field: "ProducerName",
              label: "制片人姓名",
              type: "input",
              props: {},
            },
            {
              field: "ProducerGender",
              label: "性别",
              type: "radio",
              options: [{ label: "男" }, { label: "女" }],
              props: {},
            },
            {
              field: "ProducerBirthString",
              label: "出生日期",
              type: "date",
              props: {},
            },
            {
              field: "ProducerCountry",
              label: "国籍",
              type: "input",
              props: {},
            },
            {
              field: "ProducerMail",
              label: "电子邮箱",
              type: "input",
              props: {},
            },
            {
              field: "ProducerTel",
              label: "联系电话",
              type: "number",
              props: {},
            },
          ],
        },
        // 其他人信息
        qtOptions: {
          formConfig: [
            {
              field: "MemberPosition",
              label: "在此项目中的职务",
              type: "input",
              props: {
                placeholder:
                  "（导演、联合导演、编剧、制片人、联合制片人、其他）",
              },
            },
            {
              field: "MemberName",
              label: "姓名",
              type: "input",
              props: {},
            },
            {
              field: "MemberGender",
              label: "性别",
              type: "radio",
              options: [{ label: "男" }, { label: "女" }],
              props: {},
            },
            {
              field: "MemberBirthString",
              label: "出生日期",
              type: "date",
              props: {},
            },
            {
              field: "MemberCountry",
              label: "国籍",
              type: "input",
              props: {},
            },
            {
              field: "MemberMail",
              label: "电子邮箱",
              type: "input",
              props: {},
            },
            {
              field: "MemberTel",
              label: "联系电话",
              type: "number",
              props: {},
            },
          ],
        },
        // 出品公司
        gsOptions: {
          formConfig: [
            {
              field: "CompanyName",
              label: "公司名称",
              type: "input",
              props: {},
            },
            {
              field: "CompanyIntroduction",
              label: "公司简介",
              type: "textarea",
              props: {},
            },
            {
              field: "CompanyContactName",
              label: "联系人姓名",
              type: "input",
              props: {},
            },
            {
              field: "CompanyContactPosition",
              label: "联系人职务",
              type: "input",
              props: {},
            },
            {
              field: "CompanyContactMail",
              label: "联系人电子邮件",
              type: "input",
              props: {},
            },
            {
              field: "CompanyContactTel",
              label: "联系人电话",
              type: "number",
              props: {},
            },
          ],
        },
      };
    },
    methods: {
      initData() {
        const url = process.env.BASE_URL + "/static/hth5.html";
        console.log(url);
        $(document).ready(function () {
          $("#con").load(url);
        });
      },
      // 赛道
      sdclick() {
        this.page = 2;
        for (let item of this.titleList) {
          if (item.num == this.num1) this.mtitle = item.title;
        }
        this.title = this.mtitle + "创作类型选择";
        this.$refs.zDialog.setTitle(this.title);
      },
      // 项目
      xmclick() {
        this.page = 3;
        for (let item of this.subTitleList) {
          if (item.num == this.num2) this.stitle = item.title;
        }
        this.title = this.mtitle + this.stitle;
        // console.log(this.title, this.mtitle, this.stitle);
        this.$refs.zDialog.setTitle(this.title);

        let data = [];
        const data10 = this.IDP;
        const data11 = this.WIP;
        const data20 = data10.concat(this.qx);
        const data21 = data11.concat(this.qx);
        const data30 = data10.concat(this.zj);
        const data31 = data11.concat(this.zj);

        this.joinType = this.num1 + this.num2;
        switch (this.joinType) {
          case 10:
            data = data10;
            break;
          case 11:
            data = data11;
            break;
          case 20:
            data = data20;
            break;
          case 21:
            data = data21;
            break;
          case 30:
            data = data30;
            break;
          case 31:
            data = data31;
            break;
        }
        this.options.formConfig = data;
      },
      // 详情
      mainclick() {
        this.$refs["zForm"].onSubmit(() => {
          this.page = 4;
        });
      },
      backclick() {
        setTimeout(() => {
          this.page -= 1;

          if (this.page === 1) {
            this.num1 = 0;
            this.mtitle = "";
            this.title = "创投赛道选择";
            this.$refs.zDialog.setTitle(this.title);
          }
          if (this.page === 2) {
            this.num2 = -1;
            this.stitle = "";
            this.title = this.mtitle + "创作类型选择";
            this.$refs.zDialog.setTitle(this.title);
          }
          if (this.page === 3) {
            this.checked1 = false;
            this.checked2 = false;
            this.checked3 = false;
            this.checked4 = false;
            this.checked5 = false;
            this.title = this.mtitle + this.stitle;
            this.$refs.zDialog.setTitle(this.title);
          }
        }, 300);
      },
      onSubmit() {
        if (this.isAgree) {
          this.$refs["sbForm"].onSubmit(() => {
            this.$refs["dyForm"].onSubmit(() => {
              this.btnloading = true;
              const normalData = {
                JoinType: this.joinType,
                Status: 1,
              };
              const zForm = this.$refs["zForm"].form;
              const sbForm = this.$refs["sbForm"].form;
              const dyForm = this.$refs["dyForm"].form;
              const zpForm = this.$refs["zpForm"].form;
              const qtForm = this.$refs["qtForm"].form;
              const gsForm = this.$refs["gsForm"].form;

              const submitData = {
                ...normalData,
                ...zForm,
                ...sbForm,
                ...dyForm,
                ...zpForm,
                ...qtForm,
                ...gsForm,
              };
              // console.log("submitData", submitData);

              let data = {
                postData: JSON.stringify(submitData),
                postUrl: "PunchInProjectSubmission2024",
              };
              postSubmit(data).then((res) => {
                // console.log("返回结果", res);
                if (res.code === 10000) {
                  this.page = 5;
                  this.title = "提示";
                  this.$refs.zDialog.setTitle(this.title);
                } else {
                  this.$message.error("提交错误，请稍后再试");
                }
                this.btnloading = false;
              });
            });
          });
        } else {
          this.$message.error("你必须勾选以上授权许可才可报名");
        }
      },
      handleClose() {
        console.log(11);
        this.resetForm();
        setTimeout(() => {
          this.page = this.$options.data().page;
          this.title = this.$options.data().title;
        }, 500);
      },
      resetForm() {
        this.$refs.zForm.resetForm();
        this.$refs.sbForm.resetForm();
        this.$refs.dyForm.resetForm();
        this.$refs.zpForm.resetForm();
        this.$refs.qtForm.resetForm();
        this.$refs.gsForm.resetForm();
        this.checked1 = false;
        this.checked2 = false;
        this.checked3 = false;
        this.checked4 = false;
        this.checked5 = false;
        this.num1 = 0;
        this.num2 = -1;
      },
      jbclick() {
        if (this.checked) {
          this.$refs.zDialog.show({ title: this.title });
        } else {
          this.$message.error("你必须阅读并同意上述章程后方可申请");
        }
      },
    },
    created() {},
    mounted() {
      this.initData();
    },
  };
</script>
<style lang="postcss">
  .bmtd-container {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: url("../../../assets/images/bg.png") no-repeat;
  }
  .bmtd-item {
    font-family: ali;
    font-size: 10px;
  }
  .small-button {
    font-size: 12px;
    font-family: ali;
    letter-spacing: 3px;
  }
  .small-title {
    font-size: 12px;
    font-family: ali;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .el-picker-panel {
    top: 15% !important;
  }

  .el-message {
    top: 60px !important;
  }
  .el-input {
    font-size: 10px;
  }
  .el-checkbox__label {
    font-size: 10px;
    font-family: ali;
  }
  .el-dialog__title {
    font-size: 14px;
  }
  .el-form-item__label {
    font-size: 10px;
    font-family: ali;
    line-height: 0;
  }
  .el-upload-list__item {
    font-size: 12px;
    font-family: ali;
  }
</style>
