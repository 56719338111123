<template>
  <div id="layout">
    <Header :backgroundColor="menuColor" :key="id"></Header>
    <div id="mainPage" class="my-body">
      <NewsDetails
        v-if="path == '/news' || path == '/hd/news'"
        :newsId="parseInt(id)"
        :type="type"
        :auId="parseInt(auId)"
        :key="id"
      ></NewsDetails>
      <Film v-if="path == '/zy/mvdetail'" :id="parseInt(id)" :key="id"></Film>
      <ProjectSubmission v-if="path == '/ct'"></ProjectSubmission>
      <VolunteerInfo v-if="path == '/volunteer'"></VolunteerInfo>
    </div>
    <BackTop></BackTop>
    <!-- <el-backtop>
      <div
        style="
          width: 100%;
          background-color: rgb(242, 245, 246);
          box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 6px;
          text-align: center;
          line-height: 40px;
          color: rgb(25, 137, 250);
        "
      >
        UP
      </div>
    </el-backtop> -->
  </div>
</template>
<script>
  import Header from "./../components/Header.vue";
  import NewsDetails from "./components/NewsDetails.vue";
  import Film from "./components/Film.vue";
  import ProjectSubmission from "./components/ProjectSubmission.vue";
  import VolunteerInfo from "./components/VolunteerInfo.vue";
  import BackTop from "../components/BackTop.vue";
  export default {
    name: "Layout",
    components: {
      Header,
      NewsDetails,
      Film,
      ProjectSubmission,
      VolunteerInfo,
      // SubmissionJbin,
      BackTop,
    },
    data() {
      return {
        menuColor: "rgb(3, 105, 179)",
        path: this.$route.path,
        id: this.$route.query.id,
        type: this.$route.query.type,
        auId: this.$route.query.auId,
      };
    },
    mounted() {
      console.log("path", this.path);
    },
    watch: {
      $route(to, from) {
        if (to.fullPath != from.fullPath) {
          this.id = to.query.id;
          this.type = to.query.type;
          this.auId = to.query.auId;
        }
      },
    },
  };
</script>
<style scoped>
  @import "../../assets/css/news.css";
  .my-body {
    padding-top: 50px !important;
    box-sizing: border-box;
  }
</style>
